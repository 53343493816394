// TODO: rename classes name

.custom-google-places {
  box-sizing: border-box;
  border: 0.75px solid get-color(grayStandard);
  margin-top: 5px;
  width: 100%;
  .custom-google-places__input-box {
    box-sizing: border-box;
    font-family: "Neutraface2Book";
    font-size: $font-14;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: get-color(grayStandard);
    padding: 10px 0;
    display: flex;
    position: relative;
  }
  .custom-google-places__divider {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 0.75px;
    span {
      display: inline-block;
      height: 100%;
      width: 100%;
      background-color: get-color(grayBright);
    }
  }
  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    height: 11px;
    backface-visibility: hidden;
    width: 29px;
    border-radius: 0px;
    z-index: 80;
    transform: translatey(-50%) rotate(90deg);
    &--right {
      right: 0;
    }
  }

  .main-text {
    font-family: "Neutraface2Book";
    font-size: $font-15;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: get-color(grayStandard);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--bold {
      font-family: "Neutraface2Demi";
    }
  }
  .secondary-text {
    font-family: "Neutraface2Book";
    font-size: $font-12;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: get-color(grayStandard);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--light {
      color: get-color(grayDark);
    }
  }
  .react-select__menu {
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0;
  }

  .menu-list {
    box-sizing: border-box;
    font-family: "Neutraface2Book";
    font-size: $font-14;
    font-weight: 400;
    line-height: 18px;
    border: 0.75px solid get-color(grayStandard);
    box-shadow: 0 0 5px 0 get-color(blackLight);
  }

  .react-select__value-container {
    padding: 0;
    width: 170px;
    height: 38px;
  }
  .react-select__indicators {
    color: get-color(grayStandard);
    margin-right: 10px;
    cursor: pointer;
    svg {
      color: get-color(grayDark);
      padding: 8px;
      border-radius: 50%;
      display: block;
    }
    svg:hover {
      background-color: get-color(grayBright2);
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .remove-x {
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-12;
    &:hover {
      background-color: get-color(grayBright2);
    }
  }

  &.custom-google-places--disabled {
    border: 0.75px solid get-color(grayBright2);
    .main-text,
    .secondary-text {
      cursor: default;
      color: get-color(grayBright2);
    }
    .custom-google-places__icon {
      opacity: 0.4;
    }
    .react-select__indicators {
      cursor: default;
      opacity: 0.4;
      svg:hover {
        background-color: transparent;
        transition: none;
      }
    }
  }
}
.option:active {
  background-color: get-color(grayLighter) !important;
}
