$cursor-drag-drop: grab;
$cursor-no-drop: no-drop;

.custom-dropzone {
  display: flex;
  flex: 1;
  flex-direction: column;

  .custom-dropzone__drop-zone {
    cursor: $cursor-drag-drop;
    border: 1px dashed get-color(black);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90px;

    &--disabled {
      cursor: $cursor-no-drop;
      opacity: 0.3;
    }
    &--invalid-input {
      border: 1px dashed get-color(red);
      background: get-color(redOpacity01);
    }
    &__text {
      cursor: $cursor-drag-drop;
      font-size: $font-14;
      line-height: 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: get-color(black);
      font-family: "Neutraface2Book";
      white-space: pre-line;

      &__additional {
        color: get-color(grayMiddle);
        margin-top: 22px;
      }

      &--disabled {
        cursor: $cursor-no-drop;
      }
    }
  }

  .custom-dropzone__upload-resume {
    padding-top: 12px;

    .custom-dropzone__upload-resume__box {
      font-size: $font-14;
      line-height: 17px;
      align-items: center;
      font-family: "Neutraface2Book";
      padding-bottom: 8px;

      img {
        height: 15px;
        width: 15px;
        margin-right: 8px;
      }

      &__rejected {
        display: flex;
        align-items: center;
        color: get-color(red);
        word-break: break-word;
      }

      &__accepted {
        display: flex;
        align-items: center;
        color: get-color(grayStandard);
        word-break: break-word;

        &__remove-icon {
          cursor: pointer;
          margin-left: 6px;
          height: 12px !important;
          width: 12px !important;
        }
      }
    }
  }

  .custom-dropzone__images-text-header {
    font-size: $font-14;
    font-family: "Neutraface2Demi";
    margin-top: 20px;
  }
}

.custom-dropzone__images {
  display: flex;
  gap: 2%;
  flex-direction: row;
  width: 100%;
  margin: 10px 0 40px 0;

  &__column {
    display: flex;
    width: 18.4%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 175px;
      object-fit: cover;
    }

    &__remove-btn {
      font-family: "Neutraface2Demi";
      background-color: get-color(red);
      color: get-color(white);
      cursor: pointer;
      text-align: center;
      width: 60px;
      padding: 5px 0;
      border-radius: 5px;
      font-size: $font-10;
      line-height: 10px;
      margin-top: 20px;
    }

    &__main-img-info {
      font-family: "Neutraface2Book";
      display: flex;
      align-items: center;
      color: get-color(grayMiddle);
      font-size: $font-12;
      margin-top: 10px;
      height: 20px;
      line-height: 13px;
      flex-direction: row;

      &__container {
        display: flex;
        align-items: center;
      }

      &__img-wrapper {
        margin-right: 5px;
      }
      img {
        height: 16px;
        width: 16px;
      }
    }
  }
}
