$mobile-breakpoint: 600px;
$tablet-breakpoint: 900px;

// We will use these Grid MUI breakpoints:
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

// FONT SIZES
$font-8: 8px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-32: 32px;
$font-34: 34px;
$font-35: 35px;
$font-38: 38px;
$font-70: 70px;

// END OF FONT SIZES
