.form-group {
  display: flex;

  &__label {
    margin-right: 40px;
    flex: 0 0 175px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: get-color(grayStandard);
    font-family: "Neutraface2Book";
    font-size: $font-16;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &--white {
      color: get-color(white);
    }
  }

  @media screen and (max-width: 600px) {
    &__label {
      &--max-width {
        flex: 1;
        min-width: 115px;
        max-width: 115px;
        padding-right: 5px;
      }
      &--max-width-90 {
        flex: 1;
        min-width: 90px;
        max-width: 90px;
        padding-right: 5px;
      }
    }
  }
  button {
    color: get-color(white);
  }

  &--vertical {
    flex-direction: column;
    .form-group__label {
      margin-bottom: 5px;
      flex: 0 0 100%;
    }
  }

  &__sub-label {
    color: get-color(grayMiddle);
    font-family: "Neutraface2Book";
    font-size: $font-12;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    margin-top: 5px;
  }
  &__text {
    color: get-color(grayStandard);
    font-family: "Neutraface2Book";
    font-size: $font-16;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    &__label {
      flex: 0 0 135px;
    }
  }
  @media screen and (max-width: 900px) {
    &__label {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 400px) {
    display: flex;
    justify-content: space-between;
  }
}

.MuiFormControl-root .MuiBox-root {
  .form-group {
    &__select.MuiInputBase-root {
      border-radius: 0%;
      border: 0.75px solid #3c3c3c;
      width: 80px;
      height: 44px;
    }
    .full-width {
      flex: 1;
    }
    .MuiSelect-select.MuiInputBase-input {
      color: get-color(grayStandard);
      font-family: "Neutraface2Book";
      font-size: $font-16;
      padding-left: 20px;
    }
    &--vertical {
      .form-group__select.MuiInputBase-root {
        width: unset;
      }
      .form-group__label {
        display: flex;
        justify-content: end;
        min-height: 35px;
        width: 30px;
      }
      .MuiOutlinedInput-root:first-child {
        height: 40px;
      }
      .MuiOutlinedInput-root {
        height: 40px;
        border: none;
      }
      .MuiSelect-select.MuiInputBase-input {
        color: get-color(white);
        font-family: "Neutraface2Book";
        font-size: $font-16;
      }
    }
  }

  fieldset {
    border: none;
  }

  @media screen and (max-width: 400px) {
    .form-group {
      &--vertical {
        .form-group__label {
          width: 30px;
        }
        .MuiOutlinedInput-root:first-child {
          width: 100%;
          height: 40px;
        }
        .MuiOutlinedInput-root {
          height: 40px;
          border: none;
        }
        .MuiSelect-select.MuiInputBase-input {
          color: get-color(white);
          font-family: "Neutraface2Book";
          font-size: $font-16;
        }
      }
    }
  }
}
