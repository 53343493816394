.time__component {
  display: flex;
  text-align: center;
  justify-content: space-between;

  .hours__component,
  .minutes__component {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    .arrow__up,
    .arrow__down {
      margin: 3px;
      cursor: pointer;
      user-select: none;
    }

    .time__box {
      width: 100%;
      height: 42px;
      border: 0.75px solid get-color(grayStandard);
      font-family: "Neutraface2Book";
      font-style: normal;
      font-weight: 500;
      font-size: $font-22;
      line-height: 26px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &--md {
        width: 70%;
      }
      &--w-80 {
        width: 82px;
      }
      &--w-100 {
        width: 100px;
      }
      input {
        text-align: center;
        border: none;
        font-family: "Neutraface2Book";
        font-style: normal;
        font-weight: 100;
        font-size: $font-14;
        line-height: 17px;
        outline: none;
        font-feature-settings: "pnum" on, "lnum" on;
      }
    }
  }

  .middle__component {
    margin: 0 10px;
    align-self: center;
    font-size: $font-22;
    line-height: 26px;
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
  }
}
