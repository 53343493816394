.global-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  user-select: none;
  background-color: get-color(grayBackgroundOpacity07);
  display: flex;
  justify-content: center;
  align-items: center;
}
