.booking-form {
  // TODO - should be moved to separate file (?)
  // override  MUI classes...
  #root__title {
    margin: 0;
  }
  #root__title h5,
  &__title {
    font-family: "Neutraface2Demi";
    font-weight: 100;
    color: get-color(grayStandard);
    font-size: $font-20;
    margin: 0;
    text-transform: uppercase;
    margin: 15px 0;
  }
  #root__description {
    display: none;
  }
  .MuiDivider-root {
    display: none;
  }

  // Vertical divider
  .vertical-divider {
    position: absolute;
    left: 24px;
    top: 0;
    height: 100%;
    border-right: 1px solid get-color(grayLight);
  }

  .rjsf {
    width: 100%;

    .Mui-error {
      color: get-color(red);
      font-family: "Neutraface2Demi";
      font-size: $font-13;
    }
  }

  // multistep extra options form styling
  &__extra-options {
    &__error-msg {
      color: get-color(red);
      font-family: "Neutraface2Demi";
      font-size: $font-13;
    }

    .MuiFormLabel-root {
      font-family: "Neutraface2Demi";
      color: get-color(grayStandard);
      font-size: $font-16;
      margin-bottom: 12px;
      &.Mui-focused {
        color: get-color(grayStandard);
      }
    }
  }

  .summary__details {
    display: flex;
    flex-direction: column;
    width: 100%;

    .summary__details-box {
      display: flex;
      flex-direction: row;

      &--regular {
        font-family: "Neutraface2Book";
        color: get-color(grayStandard);
      }
      &--light {
        font-family: "Neutraface2Book";
        color: get-color(grayLight2);
      }
      &--bold {
        font-family: "Neutraface2Demi";
        font-weight: 100;
        color: get-color(grayStandard);
      }
      .summary__details-data:first-child {
        flex: 0 0 70%;
      }
      .summary__details-data:last-child {
        flex: 1;
        margin-left: 8px;
        text-align: right;
        font-feature-settings: "tnum" on, "lnum" on;
      }
      .summary__details-data {
        margin-top: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: $font-14;
        line-height: 18px;
      }

      &:last-child {
        .summary__details-data {
          margin-bottom: 0;
        }
      }
      &.grey {
        color: get-color(grayBright);
      }
      &.black {
        font-family: "Neutraface2Demi";
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .booking-form .vertical-divider {
    display: none;
    left: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

#payment-form {
  #payment-message {
    color: get-color(red);
  }
}
