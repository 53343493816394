// Adyen overwritten styles

#card-container {
  // Inputs style adjustments
  .adyen-checkout__input {
    border-radius: 3px !important;
    color: get-color(grayStandard) !important;
    font-family: "Neutraface2Book" !important;
    border: 1px solid get-color(grayBright2) !important;
  }

  .adyen-checkout-input__inline-validation--invalid {
    color: get-color(grayStandard) !important;
  }

  .adyen-checkout__input--valid {
    border-bottom-color: get-color(grayBright2) !important;
  }

  .adyen-checkout__input--error,
  .adyen-checkout__input--invalid,
  .adyen-checkout__input--error:hover,
  .adyen-checkout__input--invalid:hover {
    border-color: get-color(red) !important;
  }

  .adyen-checkout__input:active,
  .adyen-checkout__input:focus,
  .adyen-checkout__input--focus,
  .adyen-checkout__input:active:hover,
  .adyen-checkout__input:focus:hover,
  .adyen-checkout__input--focus:hover {
    border: 1px solid get-color(grayBright2) !important;
    box-shadow: none !important;
  }

  // Button style adjustments
  .adyen-checkout__button {
    background-color: get-color(green) !important;
  }
  .adyen-checkout__button:focus {
    box-shadow: none !important;
  }
  .adyen-checkout__button:hover {
    background-color: get-color(green) !important;
    box-shadow: none !important;
  }
  .adyen-checkout__button:active {
    background-color: get-color(green) !important;
  }
  .adyen-checkout__button:hover:focus {
    box-shadow: none !important;
  }

  .adyen-checkout__button__text {
    color: get-color(white);
  }

  .adyen-checkout__label--focused .adyen-checkout__label__text {
    color: get-color(grayStandard) !important;
  }
}
