body {
  font-family: "Neutraface2Book", sans-serif;
  //   color: $grayStandard;
}

p {
  font-size: 1.2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: "NeutrafaceLight", sans-serif;
}

h1 {
  font-size: 3em;
  margin-top: 2em;
}
h2 {
  font-size: 2.2em;
}
h3 {
  font-size: 1.4em;
}
h4 {
  //define h4
}
h5 {
  //define h5
}

a {
  text-decoration: none;
  text-align: center;
}
