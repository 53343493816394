.offer-details {
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &__provider-logo {
    min-width: 50px;
    height: 40px;
    position: absolute;
    transform: translateX(100%);
    right: -30px;
    bottom: 0;
    object-fit: contain;
  }

  &__description {
    color: get-color(grayStandard);
    font-family: "Neutraface2Book";
    font-size: $font-14;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 8px 0;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    &__provider-logo {
      transform: none;
      bottom: -52px;
      left: 0;
    }
  }

  &__heading {
    color: get-color(grayStandard);
    font-size: $font-22;
    font-family: "Neutraface2Demi";
    font-weight: 100;
  }
  &--with-read-more {
    display: none;
  }
  &__content {
    position: relative;
    width: fit-content;
    color: get-color(grayStandard);
    font-size: $font-16;
    margin-top: 15px;
    display: flex;
    flex: 1;
    white-space: pre-line;
    text-align: justify;

    &--with-html-elements {
      display: block;
      white-space: normal;

      ul,
      ol,
      dl,
      p,
      strong {
        word-wrap: break-word;
        font-size: inherit;
      }
      p {
        margin: 13px 0px;
        margin-bottom: 0;
      }
      ul,
      ol,
      dl {
        padding: 0 40px;
        margin: 13px 0px;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: $mobile-breakpoint) {
      &--service-provider {
        flex-direction: column;
        margin-bottom: 40px;
      }
    }

    span {
      min-width: 150px;
      white-space: pre-wrap;
    }

    &--location-subheading {
      font-family: "Neutraface2Demi";
      font-weight: 100;
    }
    &--with-load-more {
      display: block;
      max-height: fit-content;
    }
    &--links {
      flex-direction: column;
      justify-content: center;
      a {
        color: get-color(grayStandard);
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        img {
          height: 14px;
          width: 14px;
          margin-left: 6px;
        }
      }
    }
  }
  &__availability-box {
    background-color: get-color(grayDarker);
    padding: 30px;
    color: get-color(white);

    &__title {
      font-family: "Neutraface2Demi";
      font-weight: 100;
      text-transform: uppercase;
      font-size: $font-20;
    }
    &__subtitle {
      font-size: $font-16;
      font-family: "Neutraface2Light";
    }

    // TODO - should be moved to separate file (?)
    // override RJSF MUI classes...

    .rjsf {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 100%;
      margin: 0 auto;
    }

    .MuiOutlinedInput-root {
      height: 40px;
      border-radius: 0;
      color: get-color(white);
      font-family: "Neutraface2Book";
      font-size: $font-16;
    }

    // border for all inputs
    .MuiOutlinedInput-notchedOutline {
      border: 0.75px solid get-color(white) !important;
    }

    // dropdown icon
    .MuiSelect-icon {
      color: white;
      right: 2px;
    }

    // label and placeholder colors
    .MuiInputLabel-formControl,
    .MuiInputLabel-asterisk {
      color: get-color(white) !important;
      font-family: "Neutraface2Book";
      font-size: $font-15 !important;
    }

    // placeholder from input
    .css-p1it4n-MuiFormLabel-root-MuiInputLabel-root,
    .css-1qjuo4e {
      margin-left: -6px !important;
      line-height: 9px !important;
      font-size: $font-11 !important;
    }

    .field-object {
      width: 100%;
      display: flex;
      width: 82%;
      width: fit-content;
    }

    .MuiGrid-container {
      display: flex;
      flex-direction: row;
    }

    .MuiFormControl-fullWidth {
      width: 100%;
      min-width: 78px;
    }

    .availability {
      width: 100%;
    }
    .availability-date {
      flex: 0 0 100%;
    }

    .availability-fields {
      flex-wrap: wrap;
      width: max-content;
      max-width: 310px;
      .property-wrapper {
        max-width: 33%;
        min-width: 33%;
      }
    }

    // change date picker icon color
    ::-webkit-calendar-picker-indicator {
      filter: invert(1) brightness(90%);
      cursor: pointer;
    }

    @media screen and (max-width: 1199px) {
      .rjsf {
        flex-direction: column;
        align-items: flex-start;
        width: unset;
        margin: unset;
      }
      .field-object {
        margin-bottom: 30px;
      }
      .MuiGrid-container {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: space-between;
        justify-content: start;
      }
      .availability-date-field {
        width: 100%;
      }

      .availability-field {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        .MuiBox-root {
          width: 100%;
        }
      }
      .MuiGrid-container.availability-fields .property-wrapper {
        flex: 0 0 30%;
        min-width: 30%;
      }
      .MuiFormControl-root .MuiBox-root .form-group__select.MuiInputBase-root {
        width: 100%;
      }
      .field-object {
        width: 100%;
      }
    }

    @media screen and (min-width: 1200px) {
      .availability-date-field {
        width: 100%;
        max-width: 150px;
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1300px) {
      .availability-date-field {
        width: 100%;
        max-width: 135px;
      }
    }
    @media screen and (min-width: 420px) and (max-width: 1199px) {
      .availability-field {
        margin: 0 8px;
      }
      .availability-field:nth-child(2) {
        margin-left: 0;
      }
      .availability-field:last-child {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 420px) {
      .availability-field {
        margin: 0 5px;
        margin-left: 0;
      }
      .availability-field:nth-child(2) {
        margin-left: 0;
      }
      .availability-field:last-child {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 400px) {
      .availability-field {
        min-width: 47%;
      }
      .availability-field:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
  &__pdfs-box {
    width: fit-content;

    &__link {
      width: 80px;
    }

    &__item {
      width: fit-content;
      margin-top: 6px;
      color: get-color(grayStandard);
      display: flex;
      font-family: "Neutraface2Book";
      font-size: $font-16;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
      img {
        height: 16px;
        width: 16px;
        margin-left: 6px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &--with-read-more {
      display: flex;
      justify-content: flex-end;
    }
    &__content {
      position: relative;

      &--with-load-more {
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;

        height: fit-content;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
      }

      &--with-max-height {
        overflow: hidden;
        max-height: 156px;
      }
    }
  }
}
