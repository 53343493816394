.input {
  color: get-color(grayStandard);
  box-sizing: border-box;
  width: 100%;
  border: 0.75px solid get-color(grayLight);
  margin-top: 0;
  height: 45px;
  outline: none;
  font-family: "Neutraface2Book";
  font-size: $font-15;
  line-height: unset;
  background-color: get-color(white);
  padding: 8px 10px !important;
  padding-top: 10px !important;
  text-overflow: ellipsis;
  border-radius: 0;

  &--textarea {
    box-sizing: border-box;
    outline: none;
    padding: 10px;
    font-family: "Neutraface2Book";
    font-weight: normal;
    font-size: $font-15;
    line-height: 18px;
    resize: none;
    border-radius: 0;
    color: get-color(grayStandard);
    background-color: get-color(white);
    border: 0.75px solid get-color(grayLight);
    height: 100%;
  }

  &--gray {
    background-color: get-color(grayLight);
    border: 0.75px solid get-color(grayLight);
  }

  &--disabled-gray {
    background: get-color(grayLight);
    color: get-color(grayBright2);
    border: 0.75px solid get-color(grayBright2);
  }
  &--disabled {
    color: get-color(grayBright2);
    border: 0.75px solid get-color(grayLight);
    background: get-color(grayLight);
  }
}

// global invalid input styles:
.invalid__input {
  border: 0.75px solid get-color(redOpacity01) !important;
  border-left: 5px solid get-color(red) !important;
  background: get-color(redOpacity01) !important;
}

input:-webkit-autofill.invalid__input,
input:-webkit-autofill:hover.invalid__input,
input:-webkit-autofill:focus.invalid__input,
input:-webkit-autofill:active.invalid__input,
input:-webkit-autofill:visited.invalid__input,
input:-internal-autofill-previewed.invalid__input,
input:-internal-autofill-selected.invalid__input {
  border: 0.75px solid get-color(red) !important;
  border-left: 5px solid get-color(red) !important;
  background: get-color(white) !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  transition: background-color 5000s;
}
