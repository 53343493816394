.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;

  &__image {
    width: 40px;
    height: 40px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__price-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font-14;
    color: get-color(grayMiddle2);
    font-variant-numeric: lining-nums proportional-nums;
    margin-top: 5px;
    text-align: center;
    &__price {
      min-width: 80px;
      white-space: pre-line;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      color: get-color(grayStandard);
      text-align: center;
      font-size: $font-14;
      font-feature-settings: "tnum" on, "lnum" on;
    }
    &__label {
      // min-height is set to align icons without label
      min-height: 15px;
      max-width: 100px;
      font-size: $font-12;
      color: get-color(grayMiddle);
      font-style: italic;
    }
  }
}
