.read-more-box {
  color: get-color(grayStandard);
  height: fit-content;
  width: fit-content;
  border-top: none;
  z-index: 99;
  font-size: $font-14;
  cursor: pointer;

  &--sm {
    font-size: $font-12;
  }

  &__arrow {
    margin-left: 8px;
    transition: all 0.3s;
    &--up {
      transform: rotate(0deg) translate(0%, 0%);
    }
    &--down {
      transform: rotate(180deg) translate(0%, 0%);
    }
  }
}

@media screen and (max-width: 480px) {
  .read-more-box {
    &__arrow {
      margin-left: 5px;
    }
  }
}
