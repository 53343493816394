.MuiAutocomplete-root {
  .clear-button {
    left: 0;
    padding: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.autocomplete-endAdornment {
  width: 25px;
  height: 28px;
  position: relative;

  .three-dots-loader {
    right: 0;
    top: 2px;
  }
}
