// Global SCSS files:
@import "fonts/fonts";
@import "palette";
@import "typography";
@import "utilities";
@import "variables";

// Components styling
@import "../../components/AppLayout/styles";
@import "../../components/AppFooter/styles";
@import "../../components/AppAcceptCookiesBox/styles";
@import "../../components/ui/HeadingBox/styles";
@import "../../components/ui/AppSubtitle/styles";
@import "../../components/ui/AppOfferCard/styles";
@import "../../components/ui/AppCard/styles";
@import "../../components/ui/CollapsibleBox/styles";
@import "../../components/AppHeader/styles";
@import "../../components/ui/SectionHeadingBox/styles";
@import "../../components/ui/AppIconBox/styles";
@import "../../components/AppSlider/styles";
@import "../../components/GlobalLoader/styles";
@import "../../components/ui/AppModal/styles";
@import "../../components/ui/ThreeDotsLoader/styles";
@import "../../components/form/AppFormInputs/styles";
@import "../../pages/Explore/OfferDetails/OfferDetailsTimeslotsModal/styles";
@import "../../pages/Booking/styles";
@import "../../pages/Booking/BookingConfirmation/styles";
@import "../../components/ui/AppForm/styles";
@import "../../components/AppDropzone/styles";
@import "../../components/AppReadMore/styles";
@import "../../components/AppDatePicker/styles";
@import "../../components/form/AppAutocomplete/styles";
@import "../../components/AppGoogleMap/styles";
@import "../../components/AppHamburgerMenu/styles";
@import "../../components/ui/AppProviderBox/styles";
@import "../../components/AppTimeComponent/styles";
@import "../../components/AppCustomRouteAsync/styles";
@import "../../components/ui/AppOffers/styles";
@import "../../components/Payment/styles";

// Pages styling
@import "../../pages/Welcome/style";
@import "../../pages/Explore/styles";
@import "../../pages/Explore/OfferDetails/styles";
@import "../../pages/PublicTransport/Sbb/styles";

// global styles
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

strong {
  font-family: "Neutraface2Demi";
  font-weight: 100;
  color: get-color(grayStandard);
}

span {
  color: get-color(grayStandard);
}

.app-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex: 1;
}

.box-header {
  color: get-color(grayStandard);
  font-family: "Neutraface2Demi";
  text-transform: uppercase;
  font-size: $font-20;
  font-weight: 100;
  margin-bottom: 20px;
}

.grid-box {
  padding: 0 35px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .box-header {
    margin-top: 25px;
  }
}

@media screen and (min-width: $mobile-breakpoint) and (max-width: 1400px) {
}

@media screen and (min-width: 1400px) {
  .app-container {
    width: 1400px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .box-header {
    margin-top: 16px;
    font-size: $font-16;
  }
}
