.slider-box {
  overflow: hidden;
  margin-bottom: 20px;
  .custom-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99;
    border-radius: 50%;
    border-color: transparent;
    cursor: pointer;

    &--next {
      right: 10px;
      img {
        position: absolute;
        left: 50%;
        transform: translateX(-3px);
        width: 10px;
        height: 18px;
      }
    }
    &--prev {
      left: 10px;
      img {
        position: absolute;
        left: 50%;
        width: 10px;
        height: 18px;
        transform: translateX(-6px) rotate(180deg);
      }
    }
  }
  &__thumbnails {
    display: block;
    flex: 0 0 20%;
  }
  &__thumbnails--vertical.slick-slider {
    display: none;
  }
  &__default-image-cover {
    width: 100%;
    height: 100%;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__main-image-carousel {
    height: 415px;
    div,
    img {
      height: 100%;
    }
    img {
      object-fit: cover;
    }
  }
  &__secondary-image-carousel {
    width: calc(100% + 20px);
    .slick-list {
      margin-top: 20px;
    }
    .slick-list .slick-slide img {
      height: 100%;
      object-fit: cover;
    }
    .slick-list .slick-slide div {
      width: calc(100% - 10px);
      height: 100px;
      margin: 0 auto;
      margin-left: -5px;
    }
  }

  &--provider .custom-arrow {
    &--next {
      right: -15px;
      border: 1px solid get-color(grayMiddle);
      background: get-color(white);
      img {
        position: absolute;
        left: 50%;
        transform: translateX(-3px);
        width: 10px;
        height: 18px;
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .offer-details {
    .slider-box__secondary-image-carousel .slick-list {
      margin-top: 0;
    }
    .slick-slider .slick-list {
      height: 100% !important;
    }
    .slider-box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      // margin-bottom: 20px;
      .slider-box__default-image-cover {
        width: 80%;
        flex: 0 0 80%;
        margin-left: 30px;
      }
      &__thumbnails--vertical {
        height: 409px !important;
        width: 100%;
        max-height: 420px;
        display: block;
      }
    }
    .slick-slider.slider-box {
      &__thumbnails {
        width: 10%;
        display: block;
        &--horizontal {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .slick-slider.slider-box {
    margin-bottom: 20px;
    &__main-image-carousel {
      height: 250px;
    }
  }
}
