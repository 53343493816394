/*@import url(https://fonts.googleapis.com/css?family=Open+Sans);*/

.App {
  text-align: center;
}

/* body {
  padding: 0%;
  margin: 0%;
  font-family: "Open Sans", sans-serif;
  text-align: center;
} */

/*nav {*/
/*  margin: 0;*/
/*  width: 100%;*/
/*  height: 80px;*/
/*  background-color: #3c3c3c;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  color: white;*/
/*  text-decoration: none;*/
/*}*/

/*a {*/
/*  color: white;*/
/*  text-decoration: none;*/
/*  margin: 10px;*/
/*  font-size: 25px;*/
/*}*/

.search {
  width: 100%;
  position: relative;
  display: flex;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.products {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-top: 60px;
}
.productsNav {
  /* width: 70%; */
  height: 80px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

.productsNav div {
  width: 150px;
  height: 50px;
  background-color: #7eac55;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listOfProducts {
  /* height: calc(100vh - 80px); */
  /* display: flex; */
  /* justify-content: center; */
  padding: 60px;
}

.productsList {
  columns: 3 auto;
  display: inline-block;
}

.productDisplay {
  width: 300px;
  height: 300px;
  background-color: rgb(233, 233, 233);
  cursor: pointer;
}
