.offers {
  display: flex;
  flex-wrap: wrap;

  &__offer {
    width: 112px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
    &__title {
      color: get-color(grayStandard);
      font-family: "Neutraface2Book";
      font-size: $font-12;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 8px;
    }
    &__image {
      font-size: $font-12;
    }
  }
}
