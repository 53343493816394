.section-heading-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  &__heading {
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    font-weight: 100;
    text-align: left;
    font-size: $font-34;
    text-transform: uppercase;
    margin-top: 20px;
  }
  &__subheading {
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    font-weight: 100;
    white-space: break-spaces;
    text-align: left;
    font-size: $font-22;
    text-transform: uppercase;
  }
  &__description {
    color: get-color(grayStandard);
    font-size: $font-20;
  }
}

@media screen and (max-width: 1200px) {
  .section-heading-box__heading {
    font-size: $font-24;
  }
  .section-heading-box__subheading {
    font-size: $font-18;
  }
}
@media screen and (max-width: 900px) {
  .section-heading-box__heading {
    font-size: $font-22;
  }
  .section-heading-box__subheading {
    font-size: $font-15;
  }
}
