.app-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: get-color(grayStandard);
  background: get-color(white);
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 30px;
  border-radius: 15px;
  outline: none;
  width: 400px;
  max-width: 100%;

  &__title {
    // Add styles here
    font-size: $font-20;
    text-align: center;
    text-transform: uppercase;
    font-family: "Neutraface2Demi";
    font-weight: 100;
    color: get-color(grayStandard);
    padding: 0 35px;
  }

  &__header {
    text-align: center;
    position: relative;

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-flow: wrap;
  }

  &--sm {
    width: 460px;
  }
  &--md {
    width: 600px;
  }
  &--lg {
    // TBD: set width
  }
}

@media screen and (max-width: 620px) {
  .app-modal {
    width: calc(100% - 20px);
  }
}
