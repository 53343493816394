.three-dots-loader {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
}

.dot1,
.dot2,
.dot3 {
  width: 6px;
  height: 6px;
  background-color: get-color(grayMiddle);
  border-radius: 50%;
  display: inline-block;
  animation: loader-animation 1.8s infinite;
}

.dot2 {
  animation-delay: 0.6s;
}

.dot3 {
  animation-delay: 1.2s;
}

@keyframes loader-animation {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
