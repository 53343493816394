.google-map {
  height: 100%;
  &__btn {
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top: -35px;
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    font-size: $font-14;
    padding: 6px 8px;
    text-align: center;
    border: none;
    text-decoration: underline;
    cursor: pointer;
  }
}
