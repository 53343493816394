.accept-box {
  position: fixed;
  bottom: 5%;
  left: 0;
  right: 0;
  max-width: 1400px;
  z-index: 999;
  margin: 0 auto;

  &__content {
    background-color: get-color(grayDarker);
    width: 90%;
    margin: 0 auto;

    &__title {
      color: get-color(white);
      font-size: $font-20;
      font-family: "Neutraface2Demi";
      text-transform: uppercase;
    }
    &__subtitle {
      color: get-color(white);
      font-size: $font-16;
    }
  }
}
