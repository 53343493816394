.app-card {
  display: flex;
  background-color: get-color(grayLight);
  margin: 0 auto;

  &__image-box {
    img {
      width: 100%;
      height: 100%;
      display: block;
      max-height: 225px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 900px) {
  .app-card {
    max-width: 450px;
  }
}
@media screen and (max-width: 600px) {
  .app-card {
    max-width: 450px;
    &__image-box {
      img {
        max-height: 225px;
      }
    }
  }
}
