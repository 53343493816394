.app-subtitle {
  color: get-color(grayStandard);
  font-size: $font-20;
  text-transform: uppercase;
  font-family: "Neutraface2Demi";
}

@media screen and (max-width: 600px) {
  .app-subtitle {
    font-size: $font-18;
  }
}
