.footer {
  font-family: "Neutraface2Book";
  background-color: get-color(grayStandard);
  display: flex;
  margin-top: auto;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  font-size: $font-14;

  &__section-title {
    font-family: "Neutraface2Demi";
    text-transform: uppercase;
    font-size: $font-14;
    color: get-color(white);
  }

  &__powered-text {
    font-family: "Neutraface2Light";
    color: get-color(grayMiddle);
    font-size: $font-14;
    &:first-child {
      padding-bottom: 8px;
    }
    &--mobile {
      display: none;
    }
  }

  a {
    color: get-color(white);
  }

  .language-box {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    padding: 20px;
    color: get-color(white);
    border: 1px solid get-color(white);

    cursor: pointer;
    &__label {
      font-size: $font-15;
      line-height: 18px;
      color: get-color(white);
      flex: 1;
    }
    &__arrow-box {
      width: 10px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
    &__arrow {
      transition: all 0.3s;
      cursor: pointer;

      &--up {
        transform: rotate(0deg) translate(0%, 0%);
      }
      &--down {
        transform: rotate(180deg) translate(0%, 0%);
      }
    }

    .language-option {
      color: get-color(grayStandard);
      border-bottom: 1px solid get-color(grayLight);
      cursor: pointer;
      padding: 20px;
      padding-top: 8px;
      padding-bottom: 6px;
      text-align: left;
    }
    .language-option:hover {
      background: get-color(grayLight);
    }
    .language-option:last-child {
      border-bottom: none;
    }
    .language-menu {
      width: 100%;
      position: absolute;
      transform: translateY(100%);
      left: 0;
      bottom: 0px;
      z-index: 99;
      background-color: get-color(white);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      display: none;
      margin-top: 23px;
      flex-direction: column;
    }
    .language-menu--open {
      display: flex;
      visibility: visible;
    }
  }
  .language-box .language-menu--disabled {
    visibility: hidden;
  }
  .language-box--disabled .language-box {
    &__label {
      opacity: 0.5;
    }
    &__arow-box {
      transform: rotate(0deg) translate(0%, 0%);
    }
    &__arrow {
      opacity: 0.5;
      transition: none;
      transform: rotate(180deg) translate(0%, 0%);
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .footer {
    .language-box {
      width: 65%;
      margin: 20px 0;
    }
  }
}

@media screen and (max-width: 520px) {
  .footer {
    .language-box {
      width: 65%;
      margin: 20px 0;
    }
  }
}

@media screen and (min-width: 480px) and (max-width: $mobile-breakpoint) {
  .footer {
    .language-box {
      width: 48%;
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 480px) {
  .footer {
    .language-box {
      width: 58%;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    &__powered-text {
      font-size: $font-12;
      &--desktop {
        display: none;
      }
      &--mobile {
        display: block;
      }
    }
  }
}
