.ma-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}

.ml-auto {
  display: block;
  margin-left: auto;
}

.flex {
  display: flex;
  flex: 1;
}

.flex-center {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.font-weight-bold {
  font-family: "Neutraface2Demi";
  font-weight: 100;
}

.default-text-transform {
  text-transform: none !important;
}
