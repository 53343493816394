.card {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &__header {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      display: block;
      height: 215px;
      object-fit: cover;
    }
    &__favorite {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
  }
  &__title {
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    text-transform: uppercase;
    font-size: $font-14;
    font-weight: 100;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    white-space: pre-line;
    height: 34px;
    max-height: 34px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  &__description {
    transition: all 0.3s ease;
    display: none;
    font-family: "Neutraface2Book";
    color: get-color(grayStandard);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: $font-14;
    font-weight: 400;

    &--desktop {
      display: block;
      width: calc(100% - 40px);
      background: get-color(grayOverlayOpacity);
      position: absolute;
      left: 20px;
      top: 20px;
      height: auto;
      overflow: hidden;
      opacity: 0;
    }
    &__content {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: -webkit-calc(100% + 2px);
      max-height: calc(100% + 2px);
      height: fit-content;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      display: -webkit-box;
      color: get-color(white);
    }
  }
  &__read-more-box {
    padding: 12px;
    padding-right: 15px;
    margin-top: 0px;
  }
  &__price-box {
    font-size: $font-14;
    color: get-color(grayMiddle2);
    font-variant-numeric: lining-nums proportional-nums;
    margin-top: 5px;
    &__price {
      text-transform: uppercase;
      margin-right: 5px;
      font-feature-settings: "tnum" on, "lnum" on;
    }
    &__non-bookable {
      margin-right: 5px;
      font-feature-settings: "tnum" on, "lnum" on;
    }
    &__label {
      font-size: $font-10;
    }
  }
  &--box:hover {
    .card__description--desktop {
      opacity: 1;
      padding: 20px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .card {
    &__description__content {
      -webkit-line-clamp: 7;
    }
  }
}
@media screen and (max-width: 1020px) {
  .card {
    &__description__content {
      -webkit-line-clamp: 6;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .card {
    flex-direction: row;
    cursor: pointer;

    &__description--desktop {
      display: none;
    }
    &__header {
      height: auto;
      img {
        display: block;
        width: 150px;
        height: 109px;
      }
    }
    &__title {
      height: 50px;
      max-height: 50px;
      -webkit-line-clamp: 3;
    }
    &__price-box {
      margin-top: auto;
      padding-top: 10px;
      &__non-bookable {
        display: none;
      }
    }
    &__description {
      color: get-color(grayStandard);
      position: relative;

      &__content {
        color: get-color(grayStandard);
        position: relative;
        width: 100%;
        border-top: none;

        &__text {
          position: relative;
          color: get-color(grayStandard);
          z-index: 99;
          font-size: $font-14;
          padding: 8px 10px 8px 8px;
          padding-right: 25px;
        }
      }

      &--mobile {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .card {
    &__read-more-box {
      padding: 10px;
      margin-top: 8px;
    }
    &__price-box {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      padding-top: 10px;
    }
    &__body {
      padding: 10px;
    }
  }
}
