.app-layout {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  .update-flag {
    font-size: 12px;
    padding: 3px 8px;
    padding-left: 10px;
    background: get-color(red);
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    &__description {
      transition: all 0.5s ease;
      opacity: 0;
      position: absolute;
      padding: 12px 15px;
    }
  }
  .update-flag:hover {
    .update-flag__description {
      bottom: -10px;
      left: 0;
      transform: translate(0%, 100%);
      opacity: 1;
      background: rgba(0, 0, 0, 0.55);
      color: get-color(white);
    }
  }
  .shape {
    top: 0;
    left: 0;
    transform: translateX(-100%);
    position: absolute;
    width: 10px;
    height: 100%;
    border: 0px solid get-color(red);
    border-left-width: 10px;
    border-right-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@media screen and (max-width: 600px) {
  .app-layout {
    .shape {
      border-left-width: 12px;
      border-right-width: 10px;
      border-top-width: 12px;
      border-bottom-width: 10px;
    }
  }
}
