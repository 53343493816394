.provider-box {
  width: 334px;
  height: 120px;
  display: flex;
  border: 1px solid get-color(grayMiddle);
  margin: 1px;
  justify-content: space-between;
  cursor: pointer;
}

.provider-image,
.provider-logo {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .provider-box {
    width: auto;
    height: auto;
  }
}
