.sbb {
  max-width: 100%;
  // DatePicker custom style
  .MuiOutlinedInput-root {
    height: 43.5px;
    border-radius: 0;
    font-family: "Neutraface2Book";
    font-size: $font-15;
    // border for all inputs
    .MuiOutlinedInput-notchedOutline {
      border: 0.75px solid get-color(greyStandard) !important;
    }
  }

  &__title {
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    font-size: $font-22;
    text-transform: uppercase;
  }

  &__error-msg {
    margin-bottom: 12px;
    font-family: "Neutraface2Demi";
    color: get-color(red);
  }

  // Vertical divider
  .vertical-divider {
    position: absolute;
    left: -4px;
    top: 0;
    height: 100%;
    border-right: 1px solid get-color(grayLight);
  }

  .row__label {
    font-family: "Neutraface2Demi";
    font-size: $font-14;
    line-height: 17px;
    text-transform: uppercase;
    color: get-color(grayDark);
    text-align: center;
  }
  .row__label-align {
    text-align: left;
  }

  // sbb row action
  .sbb-row-action {
    display: flex;
    max-width: 100%;

    &__disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .passengers-title,
  .summary-option-title {
    font-family: "Neutraface2Demi";
    font-size: $font-15;
    line-height: 17px;
    text-transform: uppercase;
  }
  .passengers-subtitle {
    font-family: "Neutraface2Demi";
    font-size: $font-12;
    text-transform: uppercase;
  }
  .passenger-remove {
    align-self: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  // summary
  .summary-passenger-box {
    font-family: "Neutraface2Italic";
    background-color: get-color(grayLight);
    flex-direction: column;
  }
  .summary-total-price-label {
    font-family: "Neutraface2Demi";
    text-transform: uppercase;
    font-size: $font-18;
  }

  // React-slick
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    margin: 0 8px;
    font-size: $font-13;
  }
  .slick-prev:before,
  .slick-next:before {
    color: get-color(black) !important;
  }
  .sbb-quick-selection-item {
    height: 50px !important;
    cursor: pointer;
    padding: 0 2px;
    border-radius: 5px;
    background-color: get-color(grayBright2);
    color: get-color(grayStandard);
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;

    &__active {
      background-color: get-color(grayStandard);
      color: get-color(white);
    }
  }
  .sbb-quick-selection-title {
    font-family: "Neutraface2Demi";
    font-size: $font-14;
    line-height: 17px;
    text-transform: uppercase;
    color: get-color(grayDark);
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 900px) {
  .sbb .vertical-divider {
    display: none;
    left: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  .sbb .summary-passenger-box {
    font-size: $font-12;
  }
  .sbb .summary-total-price-label {
    font-size: $font-15;
  }
  .sbb .MuiOutlinedInput-root {
    font-size: $font-13;
  }

  // React slick
  .sbb .slick-slide {
    font-size: $font-12;
  }
}

@media screen and (max-width: 600px) {
  // React slick
  .sbb .slick-slide {
    margin: 0 3px;
  }
}
