.date-picker-box {
  position: relative;
  .calendar-icon {
    position: absolute;
    right: 10px;
    top: 30px;
    width: 25px;
    height: 25px;
    color: get-color(white);
    fill: get-color(white);
  }
}
