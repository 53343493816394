@font-face {
  font-family: "Neutraface2Light";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fonts/Neutraface2Text-Light.eot"); /* IE9 Compat Modes */
  src: local("Neutraface2Light"), local("Neutraface2Light"),
    url("../fonts/fonts/Neutraface2Text-Light.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Light.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Neutraface2Book";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fonts/Neutraface2Text-Book.eot"); /* IE9 Compat Modes */
  src: local("Neutraface2Book"), local("Neutraface2Book"),
    url("../fonts/fonts/Neutraface2Text-Book.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Book.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Book.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Neutraface2Demi";
  src: url("../fonts/fonts/Neutra2Text-Demi.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neutraface2Italic";
  src: url("../fonts/fonts/Neutra2Text-BookItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Neutraface2Light";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fonts/Neutraface2Text-Light.eot"); /* IE9 Compat Modes */
  src: local("Neutraface2Light"), local("Neutraface2Light"),
    url("../fonts/fonts/Neutraface2Text-Light.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Light.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Neutraface2Book";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fonts/Neutraface2Text-Book.eot"); /* IE9 Compat Modes */
  src: local("Neutraface2Book"), local("Neutraface2Book"),
    url("../fonts/fonts/Neutraface2Text-Book.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Book.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/fonts/Neutraface2Text-Book.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Neutraface2Demi";
  src: url("../fonts/fonts/Neutra2Text-Demi.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neutraface2Italic";
  src: url("../fonts/fonts/Neutra2Text-BookItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
