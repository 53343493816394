.collapsible-box {
  padding: 30px;
  font-variant-numeric: lining-nums proportional-nums;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__title {
      color: get-color(grayStandard);
      font-family: "Neutraface2Demi";
      font-size: $font-14;
      font-weight: 100;
      font-variant-numeric: lining-nums proportional-nums;
      text-transform: uppercase;
    }
  }
  &__arrow {
    width: 15px;
    transition: all 0.3s;
    &--up {
      transform: rotate(0deg);
    }
    &--down {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .collapsible-box {
    padding: 20px 26px;
  }
}
