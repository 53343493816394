.timeslot-modal {
  &__loader {
    position: relative;
  }
  .timeslot-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    flex-direction: row;

    .timeslot {
      text-align: center;
      background-color: get-color(green);
      color: get-color(white);
      width: 62px;
      height: 32px;
      line-height: 30px;
      margin: 5px;
      border-radius: 4px;
      cursor: pointer;
      text-transform: uppercase;

      &--yellow {
        background-color: get-color(yellow);
        color: get-color(grayStandard);
      }

      &--full-width {
        font-family: "Neutraface2Demi";
        font-weight: 100;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 40px;
      }
    }
  }
  .timeslot-info-box {
    font-size: $font-14;
    text-transform: capitalize;
    display: flex;
    flex-wrap: wrap;
  }
}
