.explore {
  .explore-sidebar {
    &__autocomplete-list {
      display: flex;
      flex: 1;
      flex-direction: column;
      &__search-for {
        font-size: $font-12;
        color: get-color(grayStandard);
        padding-top: 3px;
      }
      &__item {
        font-size: $font-15;
        font-family: "Neutraface2Demi";
        font-weight: 100;
      }
      &__subtitle {
        font-family: "Neutraface2Book";
        font-size: $font-12;
      }
      &__footer {
        position: relative;
        font-family: "Neutraface2Book";
        font-size: $font-12;
        color: get-color(grayMiddle);
        display: block;
        margin-top: 8px;
        .three-dots-loader {
          position: relative;
          text-align: center;
          width: 100%;
          background: get-color(white);
          left: 50%;
          transform: translateX(-50%);
          height: 15px;
          bottom: 4px;
        }
      }
    }
  }
  .no-offers-message {
    color: get-color(grayStandard);
    font-size: $font-16;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .explore {
    .explore-sidebar {
      &__autocomplete-list {
        &__search-for {
          font-size: $font-11;
        }
        &__item {
          font-size: $font-13;
        }
        &__subtitle {
          font-size: $font-11;
        }
        &__footer {
          font-size: $font-11;
        }
      }
    }
  }
}
