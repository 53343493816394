.header {
  display: flex;
  position: relative;
  padding: 25px 0px;
  &__icons {
    display: flex;
    align-items: center;
    position: relative;
  }
  &__nav-container {
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    z-index: 99;
  }
  &__logo-box {
    cursor: pointer;
    img {
      height: 100%;
      max-width: 280px;
      max-height: 73px;
      min-height: 53px;
      object-fit: contain;
      vertical-align: middle;
      width: auto;
    }
  }
  &__icon-box {
    color: get-color($white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    margin-right: 0px;
    cursor: pointer;

    &--with-divider {
      border-right: 1px solid get-color($white);
      padding-right: 16px;
    }
    span {
      display: inline-block;
      color: get-color(white);
      margin-top: 8px;
      line-height: 12px;
    }
    img {
      height: 30px;
    }
  }

  .language-menu {
    background-color: get-color(white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: none;
    position: absolute;
    top: 40px;
    right: -40px;
    transform: translateX(-50%);
    margin-top: 23px;
    flex-direction: column;
    cursor: pointer;
    &__arrow-box {
      width: 10px;
      text-align: center;
      position: absolute;
      bottom: -14px;
      right: 15px;
      transform: translateX(50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__arrow {
      transition: all 0.3s;
      cursor: pointer;

      &--up {
        transform: rotate(0deg) translate(0%, 0%);
      }
      &--down {
        transform: rotate(180deg) translate(0%, 0%);
      }
    }
  }

  .language-option {
    color: get-color(grayStandard);
    border-bottom: 1px solid get-color(grayLight);
    cursor: pointer;
    padding: 20px;
    padding-top: 8px;
    padding-bottom: 6px;
    text-align: center;
  }
  .language-option:hover {
    background: get-color(grayLight);
  }
  .language-option:last-child {
    border-bottom: none;
  }

  .language-menu--open {
    display: flex;
    z-index: 99;
  }
  .language-menu--disabled {
    display: none;
  }
  .language-box--disabled {
    .language-menu__label {
      opacity: 0.5;
    }
    .language-menu__arrow {
      opacity: 0.5;
      transition: none;
      transform: rotate(180deg) translate(0%, 0%);
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .header {
    height: 103px;
    padding: 25px 35px;
    &__icon-box {
      margin: 0;
    }
    &__nav-container {
      padding: 0;
    }
    &__logo-box {
      min-height: 30px;
      max-height: 53px;

      img {
        min-height: 30px;
        max-height: 53px;
        max-width: 210px;
        height: 100%;
        width: auto;
      }
      &__img {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
