.welcome {
  &__t-and-c-text {
    color: get-color(grayMiddle);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: $font-14;
    font-style: italic;
    font-family: "Neutraface2Light";
    a {
      color: get-color(grayMiddle);
    }
  }
}

@media screen and (max-width: 600px) {
  .welcome {
    &__t-and-c-text {
      font-size: $font-12;
    }
  }
}
