.simple-heading-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  &__title {
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    text-align: center;
    font-size: $font-35;
    text-transform: uppercase;
  }
  &__text {
    color: get-color(grayStandard);
    white-space: break-spaces;
    text-align: center;
    font-size: $font-18;
    margin-top: 35px;
  }
  &__separator {
    height: 1px;
    background-color: get-color(grayLight);
    margin: 30px 24% 0 24%;
  }
}

@media screen and (max-width: 900px) {
  .simple-heading-box {
    &__icon-box {
      img {
        min-width: 10%;
        max-width: 20%;
      }
    }
    &__title {
      font-size: $font-32;
    }
    &__separator {
      margin: 30px 20% 0 20%;
    }
  }
}

@media screen and (max-width: 600px) {
  .simple-heading-box {
    &__title {
      font-size: $font-26;
    }
    &__text {
      font-size: $font-16;
      margin-top: 25px;
    }
    &__separator {
      margin: 30px 15% 0 15%;
    }
  }
}
